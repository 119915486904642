.dashboard_review_block2_cont{
    max-width: 864px;
    width: 100%;
    margin-left: 31px;
}
.dashboard_review_block2_cont_block2{
    margin-top: 50px;
}
.dashboard_review_block2_cont_block2 img{
    max-width: 864px;
    width: 100%;
}