.pricing_header{
    position: relative;
    background: url("../../assets/images/pricing_img.png");
    width: 100%;
    height: 350px;
    margin-top: 120px;
}
.pricing_header img{
   z-index: -999;
}

.pricing_title{
    margin-top: 60px ;
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%,-50%);*/
}
.try_demo_btn{
    border-radius: 3px;
    max-width: 290px;
    width: 100%;
    padding: 8px;
    margin-bottom: 117px;
}
.try_demo_btn:hover{
   transform: scale(1.2);
}
