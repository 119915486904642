.vButton {
    height: 80px;
    border-radius: 5px;
    border: transparent;
    width: 100%;
    font-size: 20px;
    line-height:50px;
    font-weight: 700;
    color: #171717;
}
.vButton:hover{
    background: #4DC7F4;
}
@media(max-width: 900px){
    .vButton {
        height: 70px;
    }
}

@media(max-width: 540px){
    .vButton {
        height: 60px;
    }
}