.home_block_4_cont {
    margin: 176px auto 0px;
    max-width: 1454px;
    width: 100%;
}

.home_block_4_cont_item {
    max-width: 336px;
    width: 100%;
    position: relative;
}

.HB4_animation1 {
    position: absolute;
    bottom: 60%;
    left: -15%;
}

.HB4_animation2 {
    position: absolute;
    top: 70%;
    left: 73%;
}

.HB4_animation3 {
    position: absolute;
    bottom: 70%;
    left: 100%;
}

@media (max-width: 1750px) {
    .home_block_4_cont {
        margin: 176px auto 0px;
        max-width: 1154px;
        width: 100%;
    }
}

@media (max-width: 1310px) {
    .home_block_4_cont {
        margin: 96px auto 0px;
        max-width: 900px;
        width: 100%;
    }

    .HB4_animation2 {
        position: absolute;
        top: unset;
        bottom: -90%;
        left: 73%;
        max-width: 150px;
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .home_block_4_cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home_block_4_cont_item {
        margin-bottom: 30px;
    }

    .HB4_animation2 {
        position: absolute;
        top: -50%;
        left: unset;
        right: -40%;
    }

    .HB4_animation3 {
        position: absolute;
        bottom: 70%;
        left: -10%;
    }
}

@media (max-width: 768px) {
    .HB4_animation3 {
        position: absolute;
        bottom: 70%;
        left: -20%;
    }
}

@media (max-width: 600px) {
    .HB4_animation3, .HB4_animation2 {
        display: none;
    }
}

@media (max-width: 444px) {
    .HB4_animation1 {
        position: absolute;
        max-width: 150px;
        width: 100%;
        left: 5%;
    }
    .home_block_4_cont{
        margin-top: 76px;
    }
    .home_block_4_cont_item {
        margin-bottom: 20px;
    }
}


