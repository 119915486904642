.customer_reviews_cont {
    margin-top: 190px;
}

.customer_reviews_cont_block1 {
    max-width: 797px;
    width: 100%;
    margin-right: 19px;
    height: 500px;
}

.customer_reviews_btn_block {
    margin-top: 122px;

}

.learn_more_btn {
    max-width: 240px;
    width: 100%;
}

.click_here_btn {
    max-width: 350px;
    width: 100%;
    margin-left: 27px;
}

.customer_reviews_btn_block::placeholder {
    color: #EB5C0A;
}

.customer_reviews_btn_block input {
    max-width: 500px;
    width: 100%;
    padding: 29px 16px;
    border: 1px solid #EB5C0A;
}

.start_free_btn {
    max-width: 200px;
    width: 100%;
    height: 75px;
}

.customer_reviews_animation_block {
    position: relative;
    max-width: 994px;
    width: 100%;
}

.reviews_main_pic {
    max-width: 994px;
    width: 100%;
}

.customer_reviews_pic_animation1, .customer_reviews_pic_animation2,
.customer_reviews_pic_animation3, .customer_reviews_pic_animation4, .customer_reviews_pic_animation5{
    max-width: 894px;
    /*width: 100%;*/
    position: absolute;
    top: -15%;
    left: 10%;
    /*transform: translate(-50%,-50%);*/
}

.customer_reviews_pic_animation1 img, .customer_reviews_pic_animation2 img, .customer_reviews_pic_animation3 img,
.customer_reviews_pic_animation4 img, .customer_reviews_pic_animation5 img{
    max-width: 794px;
    width: 100%;
}

@media (max-width: 1440px){
    .customer_reviews_cont {
        margin-top: 150px;
        justify-content: center;
        align-items: center;
    }
    .customer_reviews_cont_block1 {
        max-width: 597px;
        width: 100%;
        margin-right: 19px;
    }
    .customer_reviews_animation_block {
        position: relative;
        max-width: 654px;
        width: 100%;
    }
    .customer_reviews_pic_animation1, .customer_reviews_pic_animation2,  .customer_reviews_pic_animation3,
    .customer_reviews_pic_animation4, .customer_reviews_pic_animation5 {
        max-width: 654px;
        width: 100%;
    }

    .customer_reviews_pic_animation1 img, .customer_reviews_pic_animation2 img,  .customer_reviews_pic_animation3 img,
    .customer_reviews_pic_animation4 img, .customer_reviews_pic_animation5 img {
        max-width: 654px;
        width: 100%;
    }
}
@media(max-width: 1340px){
        .customer_reviews_pic_animation1 img, .customer_reviews_pic_animation2 img,
        .customer_reviews_pic_animation3 img, .customer_reviews_pic_animation4 img,
        .customer_reviews_pic_animation5 img {
            max-width: 600px;
            width: 100%;
        }
    .customer_reviews_pic_animation1, .customer_reviews_pic_animation2, .customer_reviews_pic_animation3,
    .customer_reviews_pic_animation4, .customer_reviews_pic_animation5 {
        max-width: 600px;
        width: 100%;
    }
}
@media(max-width: 1280px){
    .customer_reviews_pic_animation1 img, .customer_reviews_pic_animation2 img,
    .customer_reviews_pic_animation3 img, .customer_reviews_pic_animation4 img,
    .customer_reviews_pic_animation5 img {
        max-width: 550px;
        width: 100%;
    }
    .customer_reviews_pic_animation1, .customer_reviews_pic_animation2, .customer_reviews_pic_animation3,
    .customer_reviews_pic_animation4, .customer_reviews_pic_animation5 {
        max-width: 550px;
        width: 100%;
    }
    .for-reviews-cont {
        margin-left: 0px;
    }
    .customer_reviews_cont_block1 {
        margin-right: 0px;
        max-width: 900px;
    }
    .customer_reviews_animation_block {
        margin-top: 120px;
    }

    .customer_reviews_cont {
        margin-top: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 900px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    .customer_reviews_cont_block1 {
        margin-right: 0px;
        height: 200px;
    }
    .customer_reviews_btn_block{
        margin-top: 50px;
    }
}
@media(max-width: 1200px){
    ._text_container {
        text-align: center !important;
        padding-top: 40px !important;
    }
}
@media(max-width: 900px) {
    .customer_reviews_cont {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .customer_reviews_btn_block {
        display: flex;
        justify-content: center;
    }
    .customer_reviews_btn_block {
       margin-top: 30px;
    }
    .customer_reviews_btn_block input {
        max-width: 400px;
        padding: 20px 16px;
        border: 1px solid #EB5C0A;
    }
    .start_free_btn {
       height: unset;
    }
}
@media(max-width: 768px) {
    .customer_reviews_btn_block {
        margin-top: 30px;
    }
    .customer_reviews_cont_block1 {
        height: unset;
    }
    .customer_reviews_animation_block {
        margin-top: 80px;
    }
}
@media(max-width: 630px){
    .reviews_main_pic {
        width: 90%;
    }
    .customer_reviews_pic_animation1, .customer_reviews_pic_animation2, .customer_reviews_pic_animation3,
    .customer_reviews_pic_animation4, .customer_reviews_pic_animation5 {
        width: 90%;
    }
    .customer_reviews_pic_animation1 img, .customer_reviews_pic_animation2 img,
    .customer_reviews_pic_animation3 img, .customer_reviews_pic_animation4 img,
    .customer_reviews_pic_animation5 img {
        width: 90%;
    }
    .start_free_btn {
        height: 55px;
    }
    .customer_reviews_btn_block input {
        padding: 10px 14px;
    }

}

@media (max-width: 540px) {
    .customer_reviews_animation_block {
        margin-top: 70px;
    }
    .customer_reviews_btn_block {
        margin-top: 30px;
    }
    .customer_reviews_btn_block{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .customer_reviews_btn_block input {
        padding: 15px 14px;
    }
    .start_free_btn {
        margin-top: 20px;
        height: 45px;
    }
    .customer_reviews_cont {
        margin-top: 110px;
    }
}

._text_container {
    text-align: center;
    padding-top: 110px;
}

._text {
    color: #484848;
    font-style: italic;
}

._test_title {
    font-family: 'Jura';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 35px;
    color: #4DC7F4;
}

._yellow_text {
    font-family: 'Jura';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #EB5C0A;
}

@media(max-width: 465px){
    .customer_reviews_btn_block {
        margin-top: 15px;
    }
    .start_free_btn {
        margin-top: 10px;
    }
}
@media(max-width: 360px){
    .customer_reviews_animation_block {
        margin-top: 40px;
    }
    ._text_container {
        padding-top: 25px !important;
    }
}

