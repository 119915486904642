.add_location_modal{
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px 45px;
    height: 700px;
    overflow-y: auto;
}
.choose_location_cont_item{
    margin-top: 41px;
    padding: 23px 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px #4DC7F4;
    border-radius: 16px;
}
.add_location_btn{
    width: 141px;
    height: 35px;
    background: #5171FF;
    border-radius: 5px;
    margin-left: 20px;
}