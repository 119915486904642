.customers_cont{
    margin-top: 40px;
}
.customers_logo_cont{
    margin: 75px auto 0px;
    max-width: 1460px;
    width: 100%;
}
.customers_logo_cont img{
   margin-right: 10px;
    margin-top: 10px;
}
.customers_logo_cont img:hover{
    transform: scale(1.3);
}
@media(max-width: 1440px){
    .customers_logo_cont {
        margin: 25px auto 0px;
        max-width: 1200px;
    }
    .customers_cont{
        max-width: 1000px;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 1200px) {
    .customers_logo_cont{
      justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .customers_cont{
        margin-top: 62px;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media(max-width: 540px){
    .customers_cont{
        /*max-width: 450px;*/
    }
    .customers_logo_cont {
        margin: 5px auto 0px;
    }
    .customers_logo_cont img{
        max-width: 180px;
        width: 100%;
        margin-right: 10px;
        margin-top: 15px;

    }
}
@media(max-width: 360px){

}