.contact_container{
    margin: 150px auto 84px;
    z-index: 1;
}
.contact_back_pic{
    background: url("../../assets/images/contacts_back_pic.png");
    width: 100%;
    height: 340px;
    margin-bottom: 3px;
    top: -200px;
    margin-top: -300px;
}
@media(max-width: 1024px){
.contact_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}
@media(max-width: 768px){
    .contact_container {
        margin: 120px auto 84px;
        z-index: 1;
    }
}

