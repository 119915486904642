.information_block_cont {
    background-color: #F8F9FC;
    width: 100%;
    margin: 61px auto;
    position: relative;
}

.information_block_cont_inner {
    padding: 108px 10px;
    max-width: 1445px;
    margin: auto;
}

.information_block_item {
    max-width: 450px;
    width: 100%;
    height: 229px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}
.information_block_item:hover {
   border: 2px solid #EB5C0A;
    transform: scale(1.1);
}

.information_block_item_inner {
    padding-top: 30px;
    padding-left: 96px;
}

.information_block_item_content {
    margin-top: 12px;
    max-width: 320px;
    width: 100%;
    color: #818181;
}

.cloud_img {
    position: absolute;
    right: 5%;
    top: -17%;
}

.robot_img {
    position: absolute;
    bottom: 0%;
    left: 3%;
}
@media(max-width: 1440px){
    .information_block_item_inner {
        padding-top: 20px;
        padding-left: 46px;
    }
    .information_block_item {
        max-width: 400px;
        width: 100%;
    }
    .information_block_cont_inner {
        padding: 88px 10px;
        max-width: 1300px;
        margin: auto;
    }
    .robot_img {
        max-width: 220px;
        width: 100%;
        position: absolute;
        bottom: 0%;
        left: 3%;
    }

}
@media(max-width: 1300px){
    .information_block_item {
        max-width: 370px;
        width: 100%;
    }
    .information_block_item_inner {
        padding-top: 20px;
        padding-left: 26px;
    }
}

@media(max-width: 1200px){
    .information_block_cont_inner {
       max-width: 900px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    .information_block_item {
        height: 180px;
    }
    .information_block_item_inner {
        padding: 10px 56px 20px 36px;
    }
    .robot_img {
        max-width: 188px;
        width: 100%;
        left: 1%;
    }
}
@media(max-width: 900px){
    .cloud_img{
        max-width: 188px;
        width: 100%;
        top: -10%;
    }
}
@media(max-width: 768px){
    .cloud_img{
        max-width: 188px;
        width: 100%;
        top: -5%;
    }
}
@media(max-width: 540px){
    .robot_img {
        max-width: 158px;
        left: 1%;
    }
    .cloud_img{
        max-width: 158px;
        top: -5%;
    }
    .information_block_cont {
        margin: 31px auto;
        position: relative;
    }

}