.contact_form_container {
    width: 716px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    margin-left: 120px;
    z-index: 999;
    background: white;
}

.contact_form_container_inner {
    padding: 40px 28px 125px;
}
.contact_form_input_blocks_cont{
    max-width: 660px;
    width: 100%;
}
.contact_form_input_block {
    margin-bottom: 33px;
}

.contact_form_input_block_item {
   width: 100%;
}

.contact_form_input_block_item label{
    margin-bottom: 11px;
}

.contact_form_input_block_item input{
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 25px 16px;
}
.contact_form_input_block_item2{
    margin-left: 20px;
}
.contact_form_input_block_item textarea{
    width: 100%;
    height: 142px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 23px 16px;
}
.submit_btn{
    max-width: 215px;
    width: 100%;
    height: 49px;
    border-radius: 10px;
}
.submit_btn:hover{
    background: #20b9f1;
    transform: scale(1.02);
    font-weight: 700;
    font-size: 20px;
}
.blue_area{
    max-width: 730px;
    width: 100%;
    height: 340px;
    margin-bottom: 2px;
    position: absolute;
    right: 0%;
    bottom: -22.5%;
    z-index: -22;
}
@media (max-width: 1300px){
    .contact_form_container {
        max-width: 900px;
        width: 100%;
        margin-left: 20px;
    }
}
@media(max-width: 1024px){
    .contact_form_container {
        max-width: 1000px;
        width: 100%;
        margin-left: 0px;
        margin-top: 30px;
    }
}
@media(max-width: 768px){
    .contact_form_input_block {
        margin-bottom: 0px;
        flex-direction: column;
    }
    .contact_form_input_block_item {
        width: 100%;
        margin-bottom: 20px;
    }
    .contact_form_container_inner div{
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        /*margin-bottom: 20px;*/
    }
    .submit_btn_block {
       display: flex;
        justify-content: center;
        align-items: center;
    }
    .contact_form_container_inner {
        padding: 40px 15px 45px;
    }
    .contact_form_container {
        margin-top: 20px;
    }
    .contact_form_input_block_item input {
        padding: 16px 16px;
    }

}