.dashboard_settings_cont{
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(68, 71, 145, 0.15);
    border-radius: 10px;
    margin: 40px auto 110px;
    padding: 40px 20px;
}
.dashboard_settings_cont_navbar{

}




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































