.blog_article_info_cont {
    margin-top: 43px;
}

.blog_article_info_cont_blocks {
    margin-top: 28px;
}

.blog_article_info_block_cont_block1 {
    max-width: 1017px;
    width: 100%;
    /*padding-bottom: 150px;*/
    /*border-bottom: 2px solid #D9D9D9;*/
}

.blog_article_info_block_cont_img {
    margin-bottom: 62px;
    width: 100%;
}

.blog_article_info_block_cont_title {
    margin-bottom: 33px;
}

.blog_article_info_block_cont_desc {
    margin-bottom: 43px;
}

.blog_article_info_cont_posted {
    max-width: 586px;
    width: 100%;
    margin-left: 20px;
}

.search_input {
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid #D9D9D9;
}

.blog_article_info_cont_posts {
    margin-top: 33px;
    width: 100%;
    background: #F8F9FC;
}

.blog_article_info_cont_posts_inner {
    padding: 29px 32px 127px 20px;
}

.posts_cont {
    margin-top: 26px;
    margin-bottom: 26px;
}

.posts_cont_block {
    margin-bottom: 23px;
}
.posts_cont_block img{
    max-width: 138px;
    width: 100%;
    height: 106px;
}
.posts_cont_block_text{
    margin-left: 20px;
}
