.dashboard_header_container {
    width: 100%;
    height: 80px;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 999;
}
.dashboard-logo{
    cursor: pointer;
    margin-top: 5px;
}

.dashboard_header_container_inner {
    max-width: 1780px;
    margin: auto;
    padding: 15px 0px;
}

.settings_btn {
   width: 215px;
    height: 40px;
    background: #5171FF;
    border-radius: 4px;
    margin-right: 23px;
}
.settings_btn:hover {
   transform: scale(1.02);
    font-weight: 700;
}
.notification_settings_block{
    cursor: pointer;
}
.username_btn{
    margin-right: 5px;
}
.dashboard_header_dropDown {
    position: relative;
}

.dashboard_header_dropDown_content {
    background: white;
    position: absolute;
    top: 50px;
    right: 3%;
    width: 180px;
    padding: 10px 0px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.20);
    z-index: 999 !important;
}
.dashboard_header_dropDown_content a{
    padding: 15px;
    text-decoration: none;
}
.dashboard_header_dropDown_content a:hover{
   background: #e0e4ec;
    font-weight: 700;
}
.dashboard_header_btn_active{
    background: #5171FF;
    color: white;
    font-weight: 600;
}
.notification_settings_block_mobile{
    display: none;
}
@media(max-width: 768px){
    .notification_settings_block{
        display: none;
    }
    .notification_settings_block_mobile{
        display: block;
    }
    .dashboard_header_dropDown_content div{
        margin-left: 15px;
        margin-bottom: 15px;
    }
}


