.response_profiles_cont{
    margin-top: 52px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-shadow: 4px 4px 4px #EBEBEB;
    border-radius: 6px;
    width: 100%;
    padding: 42px 39px;
}
.response_profiles_cont_block1{
    max-width: 814px;
    width: 100%;
    padding: 20px 24px 35px;
    margin-right: 24px;
    background: #FFFFFF;
    /* Main Colors/Gray 2 */
    border: 2px solid #E7E7EA;
    border-radius: 4px;
    height: 100%;
}
.switch_block{
    padding-bottom: 12px;
    border-bottom: 1px solid #B7B9C0;;
}
.switch {
    position: relative;
    display: inline-block;
    width: 29px;
    height: 16px;
    left: 0px;
    top: 5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 5%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    top: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #5171FF;
}

input:focus + .slider {
    box-shadow: 0 0 1px #D1D2E0;
}

input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
    /*left: -19px;*/
}

.response_profiles_cont_block2{
    max-width: 814px;
    width: 100%;
    padding: 20px 24px 35px;
    background: #FFFFFF;
    /* Main Colors/Gray 2 */
    border: 2px solid #E7E7EA;
    border-radius: 4px;
    height: 100%;
}
.response_profiles_cont_block2 button{
    border-radius: 5px;
    max-width: 318px;
    width: 100%;
    padding: 19px;
    background: #5171FF;
}
.response_profiles_cont_block1_input_block{
    margin-top: 16px;
    max-width: 766px;
    width: 100%;
}
.response_input_block .response_input_block1, .response_input_block2{
    width: 100%;
}
.response_input_block1{
    margin-right: 26px;
    width: 100%;
}
.response_input_block1 input, .response_input_block2 input{
    margin-top: 4px;
    width: 100%;
    padding: 15px 10px;
    background: #FFFFFF;
    /* Gray Colors / Light */
    border: 1px solid #ABABAB;
    border-radius: 4px;
}
.response_input_block{
    width: 100%;
}
.response_input_block_result{
    margin-top: 15px;
}
.response_input_block textarea{
    padding: 10px;
    margin-top: 4px;
    max-width: 766px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #ABABAB;
    border-radius: 4px;
}
.response_input_block2 select{
    margin-top: 4px;
    width: 100%;
    padding: 15px 10px;
    background: #FFFFFF;
    /* Gray Colors / Light */
    border: 1px solid #ABABAB;
    border-radius: 4px;
}
.response_profiles_cont_block1_btn_block{
    margin-top: 33px;
}

.response_profiles_reset_btn{
    border: 1px solid #5171FF;
    border-radius: 5px;
    background: transparent;
    color: #5171FF;
    width:141px;
    height:36px;
    margin-right: 14px;
}
.response_profiles_reset_btn:hover{
    color:white;
   transform: scale(1.05);
    background: #5171FF;
}
.response_profiles_save_btn{
    background: #5171FF;
    border-radius: 5px;
    color: white;
    width:141px;
    height:35px;
}
.response_profiles_save_btn:hover{
    background: transparent;
    color: #5171FF;
    transform: scale(1.05);
    border: 1px solid #5171FF;
}
@media(max-width: 1200px){
    .response_profiles_cont{
        padding: 42px 15px;
    }
}
